import { JUSYOU_REKI } from "~/constants/contest";
import type { TopSlideBanner } from "~/types";

export const plan: TopSlideBanner = {
  alt: "外構・リフォーム工事プラン事例を公開中",
  path: "/images/banner/plan.png",
  link: "/plan",
};

export const set_discounts: TopSlideBanner = {
  alt: "外構・リフォーム工事をお得に。同時購入がお得なセット割",
  path: "/images/banner/set.png",
  link: "https://famitei.mobi/contents.php/set_discounts",
};

export const contest: TopSlideBanner = {
  alt: `外構・エクステリアコンテスト${JUSYOU_REKI}年連続受賞中`,
  text: {
    type: "contest",
    value: `${JUSYOU_REKI}年連続受賞中`,
    position: { x: 21, y: 22 },
  },
  path: "/images/banner/contest.png",
  link: "/contents.php/contest",
};

export const mobi: TopSlideBanner = {
  alt: "安心工事付き 工事コミコミショップ",
  path: "/images/banner/mobi.png",
  link: "https://famitei.mobi/",
};

export const campaign: TopSlideBanner = {
  alt: "ご成約感謝キャンペーン",
  path: "/images/banner/seiyakucp.png",
  link: "/contents.php/present/",
};

export const mizu: TopSlideBanner = {
  alt: "関西限定 水まわり工事",
  path: "/images/banner/mizu.png",
  link: "https://rehome.mobi/",
};

export const top_sdgs: TopSlideBanner = {
  alt: "ファミリー庭園のSDGsへの取り組み",
  path: "/images/banner/sdgs.png",
  link: "https://www.famitei.co.jp/contents.php/sdgs",
  preload: true,
};

// TODO:期間限定 12/20まで?
export const cp_kangen_carport_sc: TopSlideBanner = {
  alt: "年末大還元祭!!カーポートSCご購入キャンペーン（関西限定）",
  path: "/images/banner/cp_kangen_carport_sc.png",
  link: "https://famitei.mobi/contents.php/kangen-carportsc",
};
