<script setup lang="ts">
import type { News } from "~/types";

// TOP 更新情報
const news = ref<News[]>([]);
const newsRef = useTemplateRef("newsRef");
// 要素が画面内に入ってから取得
const { stop } = useIntersectionObserver(
  newsRef,
  async ([{ isIntersecting }]) => {
    if (isIntersecting) {
      const res = await $fetch("/api/top/news");
      if (res) {
        news.value = res;
      }
      stop();
    }
  },
);
</script>

<template>
  <LayoutWrapperDiv ref="newsRef">
    <div v-if="news" class="flex flex-col pc:flex-row gap-4 relative">
      <TextMincho
        class="text-gray-500 mx-auto flex items-end gap-2 pc:flex-col pc:items-start pc:1/4"
      >
        <p class="font-bold text-3xl">更新情報</p>
        <span class="text-sm">news</span>
      </TextMincho>
      <div class="flex flex-col mx-auto pc:w-3/4">
        <ul>
          <NewsLi
            v-for="n in news"
            :key="n.blog_num"
            :news="n"
            class="p-2 mb-4"
          />
        </ul>
        <NuxtLink
          to="/BLOG/Finfo"
          class="border mx-auto border-famitei-green text-gray-600 hover:bg-famitei-green hover:text-white rounded-full w-1/3 min-w-80 text-center p-2"
        >
          記事一覧
        </NuxtLink>
      </div>
    </div>
  </LayoutWrapperDiv>
</template>
