<script setup lang="ts">
import type { Instagram } from "~/types";
// コミコミショップ インスタグラム表示
const instagram = ref<Instagram[]>([]);

const instaRef = useTemplateRef("instaRef");
// 要素が画面内に入ってから取得
const { stop } = useIntersectionObserver(
  instaRef,
  async ([{ isIntersecting }]) => {
    if (isIntersecting) {
      const res = await $fetch("/api/instagram");
      if (res) {
        instagram.value = res;
      }
      stop();
    }
  },
);
</script>

<template>
  <div ref="instaRef">
    <!-- company -->
    <TextMinchoTitle>
      <span>Instagram</span>
      <span class="p-2 text-sm">公式インスタグラム</span>
    </TextMinchoTitle>

    <LayoutWrapperDiv
      class="flex gap-12 justify-center mb-8 tablet:mb-16 relative"
    >
      <LayoutInstagramGrid
        v-if="instagram && instagram.length"
        :data="instagram"
      />
      <LoadingIndicator
        v-else
        class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
      />
    </LayoutWrapperDiv>
  </div>
</template>
